import AntIcon from '@ant-design/icons'
import { FC } from 'react'

import { IconProps } from './types'

export const BikeIcon: FC<IconProps> = (props) => (
    <AntIcon
        component={() => (
            <svg
                width="462"
                height="202"
                viewBox="0 0 462 202"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    opacity="0.9"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M218.562 23.0168V137.589H328.142V23.0168L218.562 23.0168Z"
                    fill="#80BFF8"
                    fillOpacity="0.25"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M218.562 23.0168V137.589H328.142V23.0168L218.562 23.0168Z"
                    stroke="#80BFF8"
                    strokeWidth="1.62"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M322.202 21.6072C322.202 17.0452 325.949 13.3127 330.529 13.3127L343.068 13.3127C347.647 13.3127 351.394 17.0452 351.394 21.6072V139.096C351.394 143.657 347.647 147.39 343.068 147.39H330.529C325.949 147.39 322.202 143.657 322.202 139.096V21.6072Z"
                    fill="#F8F8FA"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M322.202 21.6072C322.202 17.0452 325.949 13.3127 330.529 13.3127L343.068 13.3127C347.647 13.3127 351.394 17.0452 351.394 21.6072V139.096C351.394 143.657 347.647 147.39 343.068 147.39H330.529C325.949 147.39 322.202 143.657 322.202 139.096V21.6072V21.6072Z"
                    stroke="#80BFF8"
                    strokeWidth="1.62"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M343.957 24.2021C343.131 24.2021 342.46 23.5344 342.46 22.7112C342.46 21.8869 343.131 21.2192 343.957 21.2192C344.784 21.2192 345.454 21.8869 345.454 22.7112C345.454 23.5344 344.784 24.2021 343.957 24.2021V24.2021Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M343.957 33.1259C343.131 33.1259 342.46 32.4582 342.46 31.635C342.46 30.8108 343.131 30.1431 343.957 30.1431C344.784 30.1431 345.454 30.8108 345.454 31.635C345.454 32.4582 344.784 33.1259 343.957 33.1259Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M343.957 33.1259C343.131 33.1259 342.46 32.4582 342.46 31.635C342.46 30.8108 343.131 30.1431 343.957 30.1431C344.784 30.1431 345.454 30.8108 345.454 31.635C345.454 32.4582 344.784 33.1259 343.957 33.1259V33.1259Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M343.957 42.0495C343.131 42.0495 342.46 41.3818 342.46 40.5586C342.46 39.7344 343.131 39.0667 343.957 39.0667C344.784 39.0667 345.454 39.7344 345.454 40.5586C345.454 41.3818 344.784 42.0495 343.957 42.0495Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M343.957 42.0495C343.131 42.0495 342.46 41.3818 342.46 40.5586C342.46 39.7344 343.131 39.0667 343.957 39.0667C344.784 39.0667 345.454 39.7344 345.454 40.5586C345.454 41.3818 344.784 42.0495 343.957 42.0495V42.0495Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M343.957 50.9731C343.131 50.9731 342.46 50.3054 342.46 49.4822C342.46 48.6579 343.131 47.9902 343.957 47.9902C344.784 47.9902 345.454 48.6579 345.454 49.4822C345.454 50.3054 344.784 50.9731 343.957 50.9731Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M343.957 50.9731C343.131 50.9731 342.46 50.3054 342.46 49.4822C342.46 48.6579 343.131 47.9902 343.957 47.9902C344.784 47.9902 345.454 48.6579 345.454 49.4822C345.454 50.3054 344.784 50.9731 343.957 50.9731V50.9731Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M343.957 59.8969C343.131 59.8969 342.46 59.2292 342.46 58.406C342.46 57.5818 343.131 56.9141 343.957 56.9141C344.784 56.9141 345.454 57.5818 345.454 58.406C345.454 59.2292 344.784 59.8969 343.957 59.8969Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M343.957 59.8969C343.131 59.8969 342.46 59.2292 342.46 58.406C342.46 57.5818 343.131 56.9141 343.957 56.9141C344.784 56.9141 345.454 57.5818 345.454 58.406C345.454 59.2292 344.784 59.8969 343.957 59.8969V59.8969Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M343.957 68.8205C343.131 68.8205 342.46 68.1528 342.46 67.3296C342.46 66.5053 343.131 65.8376 343.957 65.8376C344.784 65.8376 345.454 66.5053 345.454 67.3296C345.454 68.1528 344.784 68.8205 343.957 68.8205Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M343.957 68.8205C343.131 68.8205 342.46 68.1528 342.46 67.3296C342.46 66.5053 343.131 65.8376 343.957 65.8376C344.784 65.8376 345.454 66.5053 345.454 67.3296C345.454 68.1528 344.784 68.8205 343.957 68.8205V68.8205Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M327.904 22.7112C327.904 21.8869 328.574 21.2192 329.4 21.2192C330.227 21.2192 330.897 21.8869 330.897 22.7112C330.897 23.5344 330.227 24.2021 329.4 24.2021C328.574 24.2021 327.904 23.5344 327.904 22.7112Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M329.4 24.2021C328.574 24.2021 327.904 23.5344 327.904 22.7112C327.904 21.8869 328.574 21.2192 329.4 21.2192C330.227 21.2192 330.897 21.8869 330.897 22.7112C330.897 23.5344 330.227 24.2021 329.4 24.2021V24.2021Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M329.4 33.1259C328.574 33.1259 327.904 32.4582 327.904 31.635C327.904 30.8108 328.574 30.1431 329.4 30.1431C330.227 30.1431 330.897 30.8108 330.897 31.635C330.897 32.4582 330.227 33.1259 329.4 33.1259Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M329.4 33.1259C328.574 33.1259 327.904 32.4582 327.904 31.635C327.904 30.8108 328.574 30.1431 329.4 30.1431C330.227 30.1431 330.897 30.8108 330.897 31.635C330.897 32.4582 330.227 33.1259 329.4 33.1259V33.1259Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M329.4 42.0495C328.574 42.0495 327.904 41.3818 327.904 40.5586C327.904 39.7344 328.574 39.0667 329.4 39.0667C330.227 39.0667 330.897 39.7344 330.897 40.5586C330.897 41.3818 330.227 42.0495 329.4 42.0495Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M329.4 42.0495C328.574 42.0495 327.904 41.3818 327.904 40.5586C327.904 39.7344 328.574 39.0667 329.4 39.0667C330.227 39.0667 330.897 39.7344 330.897 40.5586C330.897 41.3818 330.227 42.0495 329.4 42.0495V42.0495Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M329.4 50.9731C328.574 50.9731 327.904 50.3054 327.904 49.4822C327.904 48.6579 328.574 47.9902 329.4 47.9902C330.227 47.9902 330.897 48.6579 330.897 49.4822C330.897 50.3054 330.227 50.9731 329.4 50.9731Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M329.4 50.9731C328.574 50.9731 327.904 50.3054 327.904 49.4822C327.904 48.6579 328.574 47.9902 329.4 47.9902C330.227 47.9902 330.897 48.6579 330.897 49.4822C330.897 50.3054 330.227 50.9731 329.4 50.9731V50.9731Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M329.4 59.8969C328.574 59.8969 327.904 59.2292 327.904 58.406C327.904 57.5818 328.574 56.9141 329.4 56.9141C330.227 56.9141 330.897 57.5818 330.897 58.406C330.897 59.2292 330.227 59.8969 329.4 59.8969Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M329.4 59.8969C328.574 59.8969 327.904 59.2292 327.904 58.406C327.904 57.5818 328.574 56.9141 329.4 56.9141C330.227 56.9141 330.897 57.5818 330.897 58.406C330.897 59.2292 330.227 59.8969 329.4 59.8969V59.8969Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M329.4 68.8205C328.574 68.8205 327.904 68.1528 327.904 67.3296C327.904 66.5053 328.574 65.8376 329.4 65.8376C330.227 65.8376 330.897 66.5053 330.897 67.3296C330.897 68.1528 330.227 68.8205 329.4 68.8205Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M329.4 68.8205C328.574 68.8205 327.904 68.1528 327.904 67.3296C327.904 66.5053 328.574 65.8376 329.4 65.8376C330.227 65.8376 330.897 66.5053 330.897 67.3296C330.897 68.1528 330.227 68.8205 329.4 68.8205V68.8205Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M335.182 26.7673C335.182 25.9431 335.852 25.2754 336.679 25.2754C337.505 25.2754 338.176 25.9431 338.176 26.7673C338.176 27.5906 337.505 28.2583 336.679 28.2583C335.852 28.2583 335.182 27.5906 335.182 26.7673Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M336.679 28.2583C335.852 28.2583 335.182 27.5906 335.182 26.7673C335.182 25.9431 335.852 25.2754 336.679 25.2754C337.505 25.2754 338.176 25.9431 338.176 26.7673C338.176 27.5906 337.505 28.2583 336.679 28.2583V28.2583Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M336.679 37.1821C335.852 37.1821 335.182 36.5144 335.182 35.6912C335.182 34.8669 335.852 34.1992 336.679 34.1992C337.505 34.1992 338.176 34.8669 338.176 35.6912C338.176 36.5144 337.505 37.1821 336.679 37.1821Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M336.679 37.1821C335.852 37.1821 335.182 36.5144 335.182 35.6912C335.182 34.8669 335.852 34.1992 336.679 34.1992C337.505 34.1992 338.176 34.8669 338.176 35.6912C338.176 36.5144 337.505 37.1821 336.679 37.1821V37.1821Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M336.679 46.1057C335.852 46.1057 335.182 45.438 335.182 44.6148C335.182 43.7905 335.852 43.1228 336.679 43.1228C337.505 43.1228 338.176 43.7905 338.176 44.6148C338.176 45.438 337.505 46.1057 336.679 46.1057Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M336.679 46.1057C335.852 46.1057 335.182 45.438 335.182 44.6148C335.182 43.7905 335.852 43.1228 336.679 43.1228C337.505 43.1228 338.176 43.7905 338.176 44.6148C338.176 45.438 337.505 46.1057 336.679 46.1057V46.1057Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M336.679 55.0295C335.852 55.0295 335.182 54.3618 335.182 53.5386C335.182 52.7143 335.852 52.0466 336.679 52.0466C337.505 52.0466 338.176 52.7143 338.176 53.5386C338.176 54.3618 337.505 55.0295 336.679 55.0295Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M336.679 55.0295C335.852 55.0295 335.182 54.3618 335.182 53.5386C335.182 52.7143 335.852 52.0466 336.679 52.0466C337.505 52.0466 338.176 52.7143 338.176 53.5386C338.176 54.3618 337.505 55.0295 336.679 55.0295V55.0295Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M336.679 63.9531C335.852 63.9531 335.182 63.2854 335.182 62.4622C335.182 61.6379 335.852 60.9702 336.679 60.9702C337.505 60.9702 338.176 61.6379 338.176 62.4622C338.176 63.2854 337.505 63.9531 336.679 63.9531Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M336.679 63.9531C335.852 63.9531 335.182 63.2854 335.182 62.4622C335.182 61.6379 335.852 60.9702 336.679 60.9702C337.505 60.9702 338.176 61.6379 338.176 62.4622C338.176 63.2854 337.505 63.9531 336.679 63.9531V63.9531Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M336.679 72.8769C335.852 72.8769 335.182 72.2092 335.182 71.386C335.182 70.5617 335.852 69.894 336.679 69.894C337.505 69.894 338.176 70.5617 338.176 71.386C338.176 72.2092 337.505 72.8769 336.679 72.8769Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M336.679 72.8769C335.852 72.8769 335.182 72.2092 335.182 71.386C335.182 70.5617 335.852 69.894 336.679 69.894C337.505 69.894 338.176 70.5617 338.176 71.386C338.176 72.2092 337.505 72.8769 336.679 72.8769V72.8769Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M332.747 131.827C332.747 129.438 334.691 127.501 337.089 127.501C339.488 127.501 341.432 129.438 341.432 131.827C341.432 134.216 339.488 136.152 337.089 136.152C334.691 136.152 332.747 134.216 332.747 131.827Z"
                    fill="#80BFF8"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M308.374 123.174C307.548 123.174 306.877 122.506 306.877 121.683C306.877 120.859 307.548 120.191 308.374 120.191C309.201 120.191 309.871 120.859 309.871 121.683C309.871 122.506 309.201 123.174 308.374 123.174Z"
                    fill="#F1F2F5"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M308.374 123.174C307.548 123.174 306.877 122.506 306.877 121.683C306.877 120.859 307.548 120.191 308.374 120.191C309.201 120.191 309.871 120.859 309.871 121.683C309.871 122.506 309.201 123.174 308.374 123.174V123.174Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M294.626 123.174C293.8 123.174 293.129 122.506 293.129 121.683C293.129 120.859 293.8 120.191 294.626 120.191C295.453 120.191 296.123 120.859 296.123 121.683C296.123 122.506 295.453 123.174 294.626 123.174Z"
                    fill="#F1F2F5"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M294.626 123.174C293.8 123.174 293.129 122.506 293.129 121.683C293.129 120.859 293.8 120.191 294.626 120.191C295.453 120.191 296.123 120.859 296.123 121.683C296.123 122.506 295.453 123.174 294.626 123.174V123.174Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M308.374 40.427C307.548 40.427 306.877 39.7593 306.877 38.9361C306.877 38.1118 307.548 37.4441 308.374 37.4441C309.201 37.4441 309.871 38.1118 309.871 38.9361C309.871 39.7593 309.201 40.427 308.374 40.427Z"
                    fill="#F1F2F5"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M308.374 40.427C307.548 40.427 306.877 39.7593 306.877 38.9361C306.877 38.1118 307.548 37.4441 308.374 37.4441C309.201 37.4441 309.871 38.1118 309.871 38.9361C309.871 39.7593 309.201 40.427 308.374 40.427V40.427Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M294.626 40.427C293.8 40.427 293.129 39.7593 293.129 38.9361C293.129 38.1118 293.8 37.4441 294.626 37.4441C295.453 37.4441 296.123 38.1118 296.123 38.9361C296.123 39.7593 295.453 40.427 294.626 40.427Z"
                    fill="#F1F2F5"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M294.626 40.427C293.8 40.427 293.129 39.7593 293.129 38.9361C293.129 38.1118 293.8 37.4441 294.626 37.4441C295.453 37.4441 296.123 38.1118 296.123 38.9361C296.123 39.7593 295.453 40.427 294.626 40.427V40.427Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M251.844 21.6072C251.844 17.0452 255.591 13.3127 260.171 13.3127L272.71 13.3127C277.29 13.3127 281.037 17.0452 281.037 21.6072V139.096C281.037 143.657 277.29 147.39 272.71 147.39H260.171C255.591 147.39 251.844 143.657 251.844 139.096V21.6072Z"
                    fill="#F8F8FA"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M251.844 21.6072C251.844 17.0452 255.591 13.3127 260.171 13.3127L272.71 13.3127C277.29 13.3127 281.037 17.0452 281.037 21.6072V139.096C281.037 143.657 277.29 147.39 272.71 147.39H260.171C255.591 147.39 251.844 143.657 251.844 139.096V21.6072V21.6072Z"
                    stroke="#80BFF8"
                    strokeWidth="1.62"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M273.6 24.2021C272.773 24.2021 272.103 23.5344 272.103 22.7112C272.103 21.8869 272.773 21.2192 273.6 21.2192C274.426 21.2192 275.096 21.8869 275.096 22.7112C275.096 23.5344 274.426 24.2021 273.6 24.2021Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M273.6 24.2021C272.773 24.2021 272.103 23.5344 272.103 22.7112C272.103 21.8869 272.773 21.2192 273.6 21.2192C274.426 21.2192 275.096 21.8869 275.096 22.7112C275.096 23.5344 274.426 24.2021 273.6 24.2021V24.2021Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M273.6 33.1259C272.773 33.1259 272.103 32.4582 272.103 31.635C272.103 30.8108 272.773 30.1431 273.6 30.1431C274.426 30.1431 275.096 30.8108 275.096 31.635C275.096 32.4582 274.426 33.1259 273.6 33.1259Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M273.6 33.1259C272.773 33.1259 272.103 32.4582 272.103 31.635C272.103 30.8108 272.773 30.1431 273.6 30.1431C274.426 30.1431 275.096 30.8108 275.096 31.635C275.096 32.4582 274.426 33.1259 273.6 33.1259V33.1259Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M273.6 42.0495C272.773 42.0495 272.103 41.3818 272.103 40.5586C272.103 39.7344 272.773 39.0667 273.6 39.0667C274.426 39.0667 275.096 39.7344 275.096 40.5586C275.096 41.3818 274.426 42.0495 273.6 42.0495Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M273.6 42.0495C272.773 42.0495 272.103 41.3818 272.103 40.5586C272.103 39.7344 272.773 39.0667 273.6 39.0667C274.426 39.0667 275.096 39.7344 275.096 40.5586C275.096 41.3818 274.426 42.0495 273.6 42.0495V42.0495Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M273.6 50.9731C272.773 50.9731 272.103 50.3054 272.103 49.4822C272.103 48.6579 272.773 47.9902 273.6 47.9902C274.426 47.9902 275.096 48.6579 275.096 49.4822C275.096 50.3054 274.426 50.9731 273.6 50.9731Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M273.6 50.9731C272.773 50.9731 272.103 50.3054 272.103 49.4822C272.103 48.6579 272.773 47.9902 273.6 47.9902C274.426 47.9902 275.096 48.6579 275.096 49.4822C275.096 50.3054 274.426 50.9731 273.6 50.9731V50.9731Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M273.6 59.8969C272.773 59.8969 272.103 59.2292 272.103 58.406C272.103 57.5818 272.773 56.9141 273.6 56.9141C274.426 56.9141 275.096 57.5818 275.096 58.406C275.096 59.2292 274.426 59.8969 273.6 59.8969Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M273.6 59.8969C272.773 59.8969 272.103 59.2292 272.103 58.406C272.103 57.5818 272.773 56.9141 273.6 56.9141C274.426 56.9141 275.096 57.5818 275.096 58.406C275.096 59.2292 274.426 59.8969 273.6 59.8969V59.8969Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M272.103 67.3296C272.103 66.5053 272.773 65.8376 273.6 65.8376C274.426 65.8376 275.096 66.5053 275.096 67.3296C275.096 68.1528 274.426 68.8205 273.6 68.8205C272.773 68.8205 272.103 68.1528 272.103 67.3296Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M273.6 68.8205C272.773 68.8205 272.103 68.1528 272.103 67.3296C272.103 66.5053 272.773 65.8376 273.6 65.8376C274.426 65.8376 275.096 66.5053 275.096 67.3296C275.096 68.1528 274.426 68.8205 273.6 68.8205V68.8205Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M259.043 24.2021C258.217 24.2021 257.546 23.5344 257.546 22.7112C257.546 21.8869 258.217 21.2192 259.043 21.2192C259.869 21.2192 260.54 21.8869 260.54 22.7112C260.54 23.5344 259.869 24.2021 259.043 24.2021Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M259.043 24.2021C258.217 24.2021 257.546 23.5344 257.546 22.7112C257.546 21.8869 258.217 21.2192 259.043 21.2192C259.869 21.2192 260.54 21.8869 260.54 22.7112C260.54 23.5344 259.869 24.2021 259.043 24.2021V24.2021Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M259.043 33.1259C258.217 33.1259 257.546 32.4582 257.546 31.635C257.546 30.8108 258.217 30.1431 259.043 30.1431C259.869 30.1431 260.54 30.8108 260.54 31.635C260.54 32.4582 259.869 33.1259 259.043 33.1259Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M259.043 33.1259C258.217 33.1259 257.546 32.4582 257.546 31.635C257.546 30.8108 258.217 30.1431 259.043 30.1431C259.869 30.1431 260.54 30.8108 260.54 31.635C260.54 32.4582 259.869 33.1259 259.043 33.1259V33.1259Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M259.043 42.0495C258.217 42.0495 257.546 41.3818 257.546 40.5586C257.546 39.7344 258.217 39.0667 259.043 39.0667C259.869 39.0667 260.54 39.7344 260.54 40.5586C260.54 41.3818 259.869 42.0495 259.043 42.0495Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M259.043 42.0495C258.217 42.0495 257.546 41.3818 257.546 40.5586C257.546 39.7344 258.217 39.0667 259.043 39.0667C259.869 39.0667 260.54 39.7344 260.54 40.5586C260.54 41.3818 259.869 42.0495 259.043 42.0495V42.0495Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M259.043 50.9731C258.217 50.9731 257.546 50.3054 257.546 49.4822C257.546 48.6579 258.217 47.9902 259.043 47.9902C259.869 47.9902 260.54 48.6579 260.54 49.4822C260.54 50.3054 259.869 50.9731 259.043 50.9731Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M259.043 50.9731C258.217 50.9731 257.546 50.3054 257.546 49.4822C257.546 48.6579 258.217 47.9902 259.043 47.9902C259.869 47.9902 260.54 48.6579 260.54 49.4822C260.54 50.3054 259.869 50.9731 259.043 50.9731V50.9731Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M259.043 59.8969C258.217 59.8969 257.546 59.2292 257.546 58.406C257.546 57.5818 258.217 56.9141 259.043 56.9141C259.869 56.9141 260.54 57.5818 260.54 58.406C260.54 59.2292 259.869 59.8969 259.043 59.8969Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M259.043 59.8969C258.217 59.8969 257.546 59.2292 257.546 58.406C257.546 57.5818 258.217 56.9141 259.043 56.9141C259.869 56.9141 260.54 57.5818 260.54 58.406C260.54 59.2292 259.869 59.8969 259.043 59.8969V59.8969Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M259.043 68.8205C258.217 68.8205 257.546 68.1528 257.546 67.3296C257.546 66.5053 258.217 65.8376 259.043 65.8376C259.869 65.8376 260.54 66.5053 260.54 67.3296C260.54 68.1528 259.869 68.8205 259.043 68.8205Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M259.043 68.8205C258.217 68.8205 257.546 68.1528 257.546 67.3296C257.546 66.5053 258.217 65.8376 259.043 65.8376C259.869 65.8376 260.54 66.5053 260.54 67.3296C260.54 68.1528 259.869 68.8205 259.043 68.8205V68.8205Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M266.321 28.2583C265.495 28.2583 264.825 27.5906 264.825 26.7673C264.825 25.9431 265.495 25.2754 266.321 25.2754C267.148 25.2754 267.818 25.9431 267.818 26.7673C267.818 27.5906 267.148 28.2583 266.321 28.2583Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M266.321 28.2583C265.495 28.2583 264.825 27.5906 264.825 26.7673C264.825 25.9431 265.495 25.2754 266.321 25.2754C267.148 25.2754 267.818 25.9431 267.818 26.7673C267.818 27.5906 267.148 28.2583 266.321 28.2583V28.2583Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M266.321 37.1821C265.495 37.1821 264.825 36.5144 264.825 35.6912C264.825 34.8669 265.495 34.1992 266.321 34.1992C267.148 34.1992 267.818 34.8669 267.818 35.6912C267.818 36.5144 267.148 37.1821 266.321 37.1821Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M266.321 37.1821C265.495 37.1821 264.825 36.5144 264.825 35.6912C264.825 34.8669 265.495 34.1992 266.321 34.1992C267.148 34.1992 267.818 34.8669 267.818 35.6912C267.818 36.5144 267.148 37.1821 266.321 37.1821V37.1821Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M266.321 46.1057C265.495 46.1057 264.825 45.438 264.825 44.6148C264.825 43.7905 265.495 43.1228 266.321 43.1228C267.148 43.1228 267.818 43.7905 267.818 44.6148C267.818 45.438 267.148 46.1057 266.321 46.1057Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M266.321 46.1057C265.495 46.1057 264.825 45.438 264.825 44.6148C264.825 43.7905 265.495 43.1228 266.321 43.1228C267.148 43.1228 267.818 43.7905 267.818 44.6148C267.818 45.438 267.148 46.1057 266.321 46.1057V46.1057Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M266.321 55.0295C265.495 55.0295 264.825 54.3618 264.825 53.5386C264.825 52.7143 265.495 52.0466 266.321 52.0466C267.148 52.0466 267.818 52.7143 267.818 53.5386C267.818 54.3618 267.148 55.0295 266.321 55.0295Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M266.321 55.0295C265.495 55.0295 264.825 54.3618 264.825 53.5386C264.825 52.7143 265.495 52.0466 266.321 52.0466C267.148 52.0466 267.818 52.7143 267.818 53.5386C267.818 54.3618 267.148 55.0295 266.321 55.0295V55.0295Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M266.321 63.9531C265.495 63.9531 264.825 63.2854 264.825 62.4622C264.825 61.6379 265.495 60.9702 266.321 60.9702C267.148 60.9702 267.818 61.6379 267.818 62.4622C267.818 63.2854 267.148 63.9531 266.321 63.9531Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M266.321 63.9531C265.495 63.9531 264.825 63.2854 264.825 62.4622C264.825 61.6379 265.495 60.9702 266.321 60.9702C267.148 60.9702 267.818 61.6379 267.818 62.4622C267.818 63.2854 267.148 63.9531 266.321 63.9531V63.9531Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M266.321 72.8769C265.495 72.8769 264.825 72.2092 264.825 71.386C264.825 70.5617 265.495 69.894 266.321 69.894C267.148 69.894 267.818 70.5617 267.818 71.386C267.818 72.2092 267.148 72.8769 266.321 72.8769Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M266.321 72.8769C265.495 72.8769 264.825 72.2092 264.825 71.386C264.825 70.5617 265.495 69.894 266.321 69.894C267.148 69.894 267.818 70.5617 267.818 71.386C267.818 72.2092 267.148 72.8769 266.321 72.8769V72.8769Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M261.581 131.827C261.581 129.438 263.525 127.501 265.923 127.501C268.321 127.501 270.266 129.438 270.266 131.827C270.266 134.216 268.321 136.152 265.923 136.152C263.525 136.152 261.581 134.216 261.581 131.827Z"
                    fill="#80BFF8"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M216.261 21.6072C216.261 17.0452 220.008 13.3127 224.588 13.3127L237.127 13.3127C241.707 13.3127 245.454 17.0452 245.454 21.6072V139.096C245.454 143.657 241.707 147.39 237.127 147.39H224.588C220.008 147.39 216.261 143.657 216.261 139.096V21.6072Z"
                    fill="#F8F8FA"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M216.261 21.6072C216.261 17.0452 220.008 13.3127 224.588 13.3127L237.127 13.3127C241.707 13.3127 245.454 17.0452 245.454 21.6072V139.096C245.454 143.657 241.707 147.39 237.127 147.39H224.588C220.008 147.39 216.261 143.657 216.261 139.096V21.6072V21.6072Z"
                    stroke="#80BFF8"
                    strokeWidth="1.62"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M238.017 24.2021C237.19 24.2021 236.52 23.5344 236.52 22.7112C236.52 21.8869 237.19 21.2192 238.017 21.2192C238.843 21.2192 239.513 21.8869 239.513 22.7112C239.513 23.5344 238.843 24.2021 238.017 24.2021V24.2021Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M238.017 33.1259C237.19 33.1259 236.52 32.4582 236.52 31.635C236.52 30.8108 237.19 30.1431 238.017 30.1431C238.843 30.1431 239.513 30.8108 239.513 31.635C239.513 32.4582 238.843 33.1259 238.017 33.1259V33.1259Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M238.017 42.0495C237.19 42.0495 236.52 41.3818 236.52 40.5586C236.52 39.7344 237.19 39.0667 238.017 39.0667C238.843 39.0667 239.513 39.7344 239.513 40.5586C239.513 41.3818 238.843 42.0495 238.017 42.0495V42.0495Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M238.017 50.9731C237.19 50.9731 236.52 50.3054 236.52 49.4822C236.52 48.6579 237.19 47.9902 238.017 47.9902C238.843 47.9902 239.513 48.6579 239.513 49.4822C239.513 50.3054 238.843 50.9731 238.017 50.9731V50.9731Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    opacity="0.85"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M238.017 59.8969C237.19 59.8969 236.52 59.2292 236.52 58.406C236.52 57.5818 237.19 56.9141 238.017 56.9141C238.843 56.9141 239.513 57.5818 239.513 58.406C239.513 59.2292 238.843 59.8969 238.017 59.8969V59.8969Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M238.017 68.8205C237.19 68.8205 236.52 68.1528 236.52 67.3296C236.52 66.5053 237.19 65.8376 238.017 65.8376C238.843 65.8376 239.513 66.5053 239.513 67.3296C239.513 68.1528 238.843 68.8205 238.017 68.8205V68.8205Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M224.269 24.2021C223.442 24.2021 222.772 23.5344 222.772 22.7112C222.772 21.8869 223.442 21.2192 224.269 21.2192C225.095 21.2192 225.765 21.8869 225.765 22.7112C225.765 23.5344 225.095 24.2021 224.269 24.2021V24.2021Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M224.269 33.1259C223.442 33.1259 222.772 32.4582 222.772 31.635C222.772 30.8108 223.442 30.1431 224.269 30.1431C225.095 30.1431 225.765 30.8108 225.765 31.635C225.765 32.4582 225.095 33.1259 224.269 33.1259V33.1259Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M224.269 42.0495C223.442 42.0495 222.772 41.3818 222.772 40.5586C222.772 39.7344 223.442 39.0667 224.269 39.0667C225.095 39.0667 225.765 39.7344 225.765 40.5586C225.765 41.3818 225.095 42.0495 224.269 42.0495V42.0495Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M224.269 50.9731C223.442 50.9731 222.772 50.3054 222.772 49.4822C222.772 48.6579 223.442 47.9902 224.269 47.9902C225.095 47.9902 225.765 48.6579 225.765 49.4822C225.765 50.3054 225.095 50.9731 224.269 50.9731V50.9731Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M224.269 59.8969C223.442 59.8969 222.772 59.2292 222.772 58.406C222.772 57.5818 223.442 56.9141 224.269 56.9141C225.095 56.9141 225.765 57.5818 225.765 58.406C225.765 59.2292 225.095 59.8969 224.269 59.8969V59.8969Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M224.269 68.8205C223.442 68.8205 222.772 68.1528 222.772 67.3296C222.772 66.5053 223.442 65.8376 224.269 65.8376C225.095 65.8376 225.765 66.5053 225.765 67.3296C225.765 68.1528 225.095 68.8205 224.269 68.8205V68.8205Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M230.738 28.2583C229.912 28.2583 229.242 27.5906 229.242 26.7673C229.242 25.9431 229.912 25.2754 230.738 25.2754C231.565 25.2754 232.235 25.9431 232.235 26.7673C232.235 27.5906 231.565 28.2583 230.738 28.2583V28.2583Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M230.738 37.1821C229.912 37.1821 229.242 36.5144 229.242 35.6912C229.242 34.8669 229.912 34.1992 230.738 34.1992C231.565 34.1992 232.235 34.8669 232.235 35.6912C232.235 36.5144 231.565 37.1821 230.738 37.1821V37.1821Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M230.738 46.1057C229.912 46.1057 229.242 45.438 229.242 44.6148C229.242 43.7905 229.912 43.1228 230.738 43.1228C231.565 43.1228 232.235 43.7905 232.235 44.6148C232.235 45.438 231.565 46.1057 230.738 46.1057V46.1057Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M230.738 55.0295C229.912 55.0295 229.242 54.3618 229.242 53.5386C229.242 52.7143 229.912 52.0466 230.738 52.0466C231.565 52.0466 232.235 52.7143 232.235 53.5386C232.235 54.3618 231.565 55.0295 230.738 55.0295V55.0295Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M230.738 63.9531C229.912 63.9531 229.242 63.2854 229.242 62.4622C229.242 61.6379 229.912 60.9702 230.738 60.9702C231.565 60.9702 232.235 61.6379 232.235 62.4622C232.235 63.2854 231.565 63.9531 230.738 63.9531V63.9531Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M230.738 72.8769C229.912 72.8769 229.242 72.2092 229.242 71.386C229.242 70.5617 229.912 69.894 230.738 69.894C231.565 69.894 232.235 70.5617 232.235 71.386C232.235 72.2092 231.565 72.8769 230.738 72.8769V72.8769Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M231.149 136.152C228.751 136.152 226.807 134.216 226.807 131.827C226.807 129.438 228.751 127.501 231.149 127.501C233.547 127.501 235.491 129.438 235.491 131.827C235.491 134.216 233.547 136.152 231.149 136.152V129.488Z"
                    fill="#80BFF8"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M153.138 20.1472L165.026 16.1721C169.368 14.7206 174.113 17.0721 175.57 21.3966C175.57 21.3966 184.589 48.1627 189.099 61.5458C197.1 85.2895 213.102 132.777 213.102 132.777C214.56 137.103 212.2 141.828 207.859 143.28L195.971 147.255C191.629 148.707 186.885 146.356 185.428 142.031L147.895 30.65C146.438 26.3255 148.797 21.5987 153.138 20.1472Z"
                    fill="#F8F8FA"
                    stroke="#80BFF8"
                    strokeWidth="1.62"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M171.093 36.1652C170.83 35.3845 171.251 34.5395 172.036 34.2772C172.82 34.0149 173.668 34.4358 173.931 35.2166C174.195 35.9973 173.772 36.8423 172.988 37.1046C172.205 37.3669 171.355 36.9459 171.093 36.1652Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M173.519 45.0888C173.256 44.3081 173.678 43.4631 174.462 43.2008C175.246 42.9385 176.094 43.3594 176.358 44.1401C176.621 44.9208 176.198 45.7658 175.415 46.0282C174.631 46.2905 173.782 45.8695 173.519 45.0888Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M176.754 53.2014C176.491 52.4206 176.912 51.5757 177.697 51.3133C178.481 51.051 179.329 51.472 179.593 52.2527C179.856 53.0334 179.433 53.8784 178.65 54.1407C177.866 54.403 177.017 53.9821 176.754 53.2014Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M181.076 63.0643C180.292 63.3266 179.443 62.9057 179.18 62.1249C178.917 61.3442 179.338 60.4992 180.123 60.2369C180.907 59.9746 181.755 60.3956 182.019 61.1763C182.282 61.957 181.859 62.802 181.076 63.0643V63.0643Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M184.31 71.1768C183.527 71.4391 182.677 71.0182 182.415 70.2375C182.152 69.4568 182.573 68.6118 183.358 68.3495C184.142 68.0872 184.99 68.5081 185.253 69.2888C185.517 70.0695 185.094 70.9145 184.31 71.1768V71.1768Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M186.737 80.1004C185.953 80.3627 185.103 79.9418 184.841 79.1611C184.578 78.3804 184.999 77.5354 185.784 77.2731C186.568 77.0107 187.416 77.4317 187.68 78.2124C187.943 78.9931 187.52 79.8381 186.737 80.1004V80.1004Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M157.069 41.0327C156.805 40.2519 157.227 39.407 158.012 39.1446C158.795 38.8823 159.644 39.3033 159.907 40.084C160.17 40.8647 159.748 41.7097 158.964 41.972C158.18 42.2343 157.331 41.8134 157.069 41.0327Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M161.39 50.0845C160.606 50.3469 159.757 49.9259 159.495 49.1452C159.231 48.3645 159.653 47.5195 160.438 47.2572C161.221 46.9949 162.07 47.4158 162.333 48.1965C162.596 48.9772 162.174 49.8222 161.39 50.0845V50.0845Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M164.625 59.0081C163.841 59.2704 162.992 58.8495 162.73 58.0688C162.466 57.2881 162.888 56.4431 163.673 56.1808C164.456 55.9185 165.305 56.3394 165.568 57.1201C165.831 57.9008 165.409 58.7458 164.625 59.0081V59.0081Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M167.328 67.1207C166.544 67.383 165.694 66.962 165.432 66.1813C165.169 65.4006 165.59 64.5556 166.375 64.2933C167.159 64.031 168.007 64.452 168.271 65.2327C168.534 66.0134 168.111 66.8584 167.328 67.1207V67.1207Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M170.562 76.0443C169.779 76.3066 168.929 75.8856 168.667 75.1049C168.404 74.3242 168.825 73.4792 169.61 73.2169C170.394 72.9546 171.242 73.3755 171.505 74.1562C171.769 74.937 171.346 75.782 170.562 76.0443V76.0443Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M173.797 84.1568C173.014 84.4191 172.164 83.9982 171.902 83.2175C171.639 82.4368 172.06 81.5918 172.845 81.3295C173.629 81.0671 174.477 81.4881 174.74 82.2688C175.004 83.0495 174.581 83.8945 173.797 84.1568V84.1568Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M167.328 43.5946C166.544 43.8569 165.694 43.4359 165.432 42.6552C165.169 41.8745 165.59 41.0295 166.375 40.7672C167.159 40.5049 168.007 40.9258 168.271 41.7065C168.534 42.4873 168.111 43.3322 167.328 43.5946V43.5946Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    opacity="0.85"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M170.562 52.5181C169.779 52.7805 168.929 52.3595 168.667 51.5788C168.404 50.7981 168.825 49.9531 169.61 49.6908C170.394 49.4285 171.242 49.8494 171.505 50.6301C171.769 51.4108 171.346 52.2558 170.562 52.5181V52.5181Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M172.988 60.6307C172.205 60.893 171.355 60.4721 171.093 59.6913C170.83 58.9106 171.251 58.0656 172.036 57.8033C172.82 57.541 173.668 57.962 173.931 58.7427C174.195 59.5234 173.772 60.3684 172.988 60.6307V60.6307Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M176.223 69.5543C175.44 69.8166 174.59 69.3956 174.328 68.6149C174.065 67.8342 174.486 66.9892 175.271 66.7269C176.055 66.4646 176.903 66.8855 177.166 67.6663C177.43 68.447 177.007 69.292 176.223 69.5543V69.5543Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M178.65 77.6668C177.866 77.9291 177.017 77.5082 176.754 76.7275C176.491 75.9468 176.912 75.1018 177.697 74.8395C178.481 74.5772 179.329 74.9981 179.593 75.7788C179.856 76.5595 179.433 77.4045 178.65 77.6668V77.6668Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M181.884 86.5904C181.101 86.8527 180.251 86.4318 179.989 85.6511C179.726 84.8704 180.147 84.0254 180.932 83.763C181.716 83.5007 182.564 83.9217 182.827 84.7024C183.091 85.4831 182.668 86.3281 181.884 86.5904V86.5904Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M198.566 135.929C196.293 136.689 193.831 135.469 193.068 133.204C192.305 130.94 193.529 128.488 195.803 127.728C198.076 126.967 200.538 128.187 201.302 130.451C202.065 132.717 200.84 135.169 198.566 135.929Z"
                    fill="#80BFF8"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M193.655 11.3773L196.281 9.8667L198.907 11.3773V14.3975L196.281 15.9081L193.655 14.3975V11.3773V11.3773Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M263.204 192.285L265.83 190.774L268.456 192.285V195.305L265.83 196.816L263.204 195.305V192.285V192.285Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M201.942 42.3164L204.568 43.827V46.8472L201.942 48.3579L199.316 46.8472V43.827L201.942 42.3164Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M205.177 32.5815L207.803 34.0922V37.1124L205.177 38.623L202.551 37.1124V34.0922L205.177 32.5815Z"
                    stroke="#80BFF8"
                    strokeWidth="0.81"
                />
                <path d="M30.709 149.797H410.801" stroke="#80BFF8" strokeWidth="2.43" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M128.737 157.504C132.786 157.504 140.957 157.504 140.957 157.504C140.957 157.504 148.694 157.504 152.586 157.504C277.808 157.504 410.396 157.504 410.396 157.504C410.396 157.504 350.148 204.556 276.151 201.717C202.155 198.877 177.893 186.708 159.293 179.8C140.693 172.892 103.923 170.078 78.412 175.757C52.9005 181.435 30.3047 157.504 30.3047 157.504C30.3047 157.504 36.363 157.504 128.737 157.504Z"
                    fill="#80BFF8"
                    fillOpacity="0.25"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M37.5831 147.363C57.235 147.363 73.1661 131.382 73.1661 111.668C73.1661 91.9547 57.235 75.9736 37.5831 75.9736C17.9311 75.9736 2 91.9547 2 111.668C2 131.382 17.9311 147.363 37.5831 147.363Z"
                    fill="#80BFF8"
                    fillOpacity="0.25"
                    stroke="#80BFF8"
                    strokeWidth="2.43"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M37.8526 142.496C54.6759 142.496 68.3139 128.815 68.3139 111.939C68.3139 95.0626 54.6759 81.3818 37.8526 81.3818C21.0293 81.3818 7.39136 95.0626 7.39136 111.939C7.39136 128.815 21.0293 142.496 37.8526 142.496Z"
                    fill="#F8F8FA"
                />
                <path d="M41.6265 108.018L80.4443 119.375" stroke="#80BFF8" strokeWidth="0.81" />
                <path d="M67.9096 140.873L34.7527 115.725" stroke="#80BFF8" strokeWidth="0.81" />
                <path
                    opacity="0.4"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M70.3102 128.953C69.2142 132.136 70.7569 135.564 73.75 136.595C76.7428 137.626 80.0698 135.874 81.1657 132.691C82.2616 129.509 80.718 126.081 77.7252 125.05C74.7321 124.02 71.406 125.771 70.3102 128.953ZM67.8882 128.119C69.4727 123.517 74.2837 120.984 78.6121 122.475C82.9408 123.965 85.1723 128.923 83.5877 133.525C82.0028 138.128 77.1921 140.661 72.8634 139.17C68.535 137.68 66.3032 132.722 67.8882 128.119L80.818 133.579Z"
                    fill="#80BFF8"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M65.7959 127.16C63.8667 132.763 66.5833 138.797 71.8515 140.611C77.1204 142.425 82.9758 139.342 84.905 133.74C86.834 128.137 84.1177 122.104 78.8488 120.289C73.5805 118.475 67.725 121.557 65.7959 127.16ZM63.3739 126.326C65.792 119.303 73.1318 115.44 79.7357 117.714C86.3395 119.988 89.7458 127.551 87.3276 134.574C84.9094 141.597 77.5684 145.461 70.9646 143.187C64.3607 140.913 60.9556 133.349 63.3739 126.326Z"
                    fill="#80BFF8"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M33.6475 109.667C32.7761 112.198 33.9412 114.902 36.2447 115.695C38.5484 116.488 41.1312 115.075 42.0026 112.544C42.8739 110.013 41.7089 107.309 39.4052 106.516C37.1017 105.723 34.5188 107.137 33.6475 109.667ZM32.5885 109.302C33.6808 106.13 36.9184 104.359 39.8058 105.353C42.6932 106.347 44.154 109.737 43.0618 112.909C41.9695 116.081 38.7314 117.853 35.844 116.859C32.9566 115.864 31.4963 112.475 32.5885 109.302Z"
                    fill="#80BFF8"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M40.4495 111.942L81.0304 86.8457L128.751 97.9073L73.3222 126.112L73.8173 127.254L40.4495 111.942ZM35.945 113.87L73.2077 130.969C74.0204 131.257 74.7505 131.02 75.2123 130.47L76.3646 133.126L131.858 104.888L133.622 145.573C133.668 146.632 134.324 147.486 135.219 147.794C135.513 147.895 135.833 147.937 136.167 147.909C137.51 147.791 138.551 146.555 138.49 145.149L135.687 80.5105L139.382 81.7829L136.987 88.7397C136.715 89.5298 137.096 90.378 137.84 90.6341L151.701 95.4067C152.064 95.532 152.468 95.4954 152.817 95.3041L162.72 89.8886C163.063 90.5044 163.403 91.4619 163.206 92.6633C162.87 94.7096 160.92 97.9641 153.535 101.852C152.816 102.229 152.504 103.135 152.838 103.875C153.004 104.245 153.301 104.504 153.645 104.622C153.989 104.741 154.382 104.72 154.741 104.531C161.616 100.912 165.418 97.0296 166.042 92.9921C166.617 89.2691 164.234 86.9506 164.132 86.8537C163.7 86.4419 163.059 86.3634 162.523 86.6564L152.116 92.3486L140.171 88.2355L142.074 82.7097L143.778 83.2966C145.042 83.7315 146.441 82.9948 146.903 81.6517C147.366 80.3083 146.717 78.8662 145.454 78.4313L133.925 74.4616C133.159 74.198 132.304 74.3591 131.647 74.8907C130.989 75.4223 130.616 76.2541 130.654 77.1071L131.421 94.7996L83.3247 83.6508L85.4195 71.2326C85.4936 70.2373 84.7866 69.3969 83.8383 69.3582C82.8913 69.3186 82.0643 70.0941 81.9909 71.0903L79.9018 83.423L35.8525 110.665C35.2881 111.014 34.9449 111.659 34.9648 112.334C34.9835 113.01 35.3624 113.602 35.945 113.87Z"
                    fill="#80BFF8"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M122.093 133.167C122.093 133.167 119.262 133.167 119.262 134.383C119.262 135.6 130.18 144.524 130.18 144.524L122.093 133.167Z"
                    fill="#80BFF8"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M148.376 133.167C148.376 133.167 151.206 133.167 151.206 134.383C151.206 135.6 140.289 144.524 140.289 144.524L148.376 133.167Z"
                    fill="#80BFF8"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M116.922 137.81C116.922 137.81 114.298 138.87 114.754 139.998C115.209 141.126 128.675 145.311 128.675 145.311L116.922 137.81Z"
                    fill="#80BFF8"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M152.029 138.639C152.029 138.639 154.653 139.702 154.198 140.831C153.744 141.959 140.288 146.131 140.288 146.131L152.029 138.639Z"
                    fill="#80BFF8"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M76.4009 59.7487C74.9743 59.391 70.3698 58.0793 69.9313 60.56C69.5752 62.5742 72.5119 68.5749 75.5922 69.4837C82.4662 71.5118 90.1489 68.6724 97.0229 70.7006C103.897 72.7287 103.5 69.0855 102.279 67.8612C99.8534 65.4275 79.6357 60.56 76.4009 59.7487Z"
                    stroke="#80BFF8"
                    strokeWidth="2.43"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M388.267 147.769C407.919 147.769 423.85 131.788 423.85 112.074C423.85 92.3602 407.919 76.3792 388.267 76.3792C368.615 76.3792 352.684 92.3602 352.684 112.074C352.684 131.788 368.615 147.769 388.267 147.769Z"
                    fill="#80BFF8"
                    fillOpacity="0.25"
                    stroke="#80BFF8"
                    strokeWidth="2.43"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M388.537 142.901C405.36 142.901 418.998 129.22 418.998 112.344C418.998 95.4681 405.36 81.7874 388.537 81.7874C371.713 81.7874 358.075 95.4681 358.075 112.344C358.075 129.22 371.713 142.901 388.537 142.901Z"
                    fill="#F8F8FA"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M370.901 93.4153C369.567 94.1633 367.931 96.0093 366.413 99.5613C361.561 110.919 362.774 121.059 362.774 121.059C362.774 121.059 364.391 101.589 370.861 97.9388"
                    fill="#80BFF8"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M406.135 131.605C407.469 130.857 409.105 129.011 410.623 125.459C415.475 114.102 414.262 103.961 414.262 103.961C414.262 103.961 412.644 123.431 406.175 127.082"
                    fill="#80BFF8"
                />
            </svg>
        )}
        {...props}
    />
)
